<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="paperclip"/> Template Prompts
            </CCardHeader>
            <CCardBody>
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active Template Prompt/s <CBadge color="success">( {{ [...new Set(dtp_lists_active.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="dtp_lists_active"
                                    :fields="dtp_lists_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    hover
                                    :loading="this.table_loading"
                                >
                                <template slot="no-items-view" v-if="this.table_loading">
                                    <center>
                                        <CSpinner
                                            style="width:4rem;height:4rem;"
                                            color="info"
                                        />
                                    </center>
                                </template>
                                <template #icon="{item}">
                                    <td>
                                        <center>
                                            <CIcon :name="item.icon" size="xl" />
                                        </center>
                                    </td>
                                </template>
                                <template #name="{item}">
                                    <td :title="item.name">                
                                        {{item.name.length > 30 ? item.name.substring(0, 30) + '...' :  item.name}}
                                    </td>
                                </template>
                                <template #description="{item}">
                                    <td :title="item.description">                
                                        {{item.description.length > 15 ? item.description.substring(0, 15) + '...' :  item.description}}
                                    </td>
                                </template>
                                <template #prompt="{item}">
                                    <td :title="item.prompt">                
                                        {{item.prompt.length > 70 ? item.prompt.substring(0, 70) + '...' :  item.prompt}}
                                    </td>
                                </template>
                                <template #created_at="{item}">
                                    <td>
                                        {{ item.created_at }}
                                    </td>
                                </template>
                                <template #Action="{item}">
                                    <td>
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/drs/document-template-prompt/${item.id}`}">
                                            <CButton 

                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                    </td>
                                </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Inactive Template Prompt/s <CBadge color="danger">( {{ [...new Set(dt_lists_inactive.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="dt_lists_inactive"
                                    :fields="dtp_lists_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                    hover
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    
                                    <template #name="{item}">
                                        <td :title="item.name">                
                                            {{item.name.length > 30 ? item.name.substring(0, 30) + '...' :  item.name}}
                                        </td>
                                    </template>
                                    <template #description="{item}">
                                        <td :title="item.description">                
                                            {{item.description.length > 15 ? item.description.substring(0, 15) + '...' :  item.description}}
                                        </td>
                                    </template>
                                    <template #prompt="{item}">
                                        <td :title="item.prompt">                
                                            {{item.prompt.length > 70 ? item.prompt.substring(0, 70) + '...' :  item.prompt}}
                                        </td>
                                    </template>
                                    <template #updated_at="{item}">
                                        <td>
                                            <small>{{ item.updated_at }}</small>
                                        </td>
                                    </template>
                                    <template #Action="{item}">
                                        <td >
                                            <template v-if="$store.getters['can']('restore-document-template-prompt')">
                                                <CButton 
                                                        size="sm"
                                                        shape="pill"
                                                        color="success" 
                                                        :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                        @click="restore(item)"
                                                    >

                                                    <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                                :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                </CButton>
                                            </template>
                                            <template v-else>
                                                <i><b>No permission to restore.</b></i>
                                            </template>
                                        </td>
                                    </template>  
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                </CTabs>




            </CCardBody>
        </CCard>   
    </div>
</template>
<script>
// import CHideShowBtn from '../../components/CHideShowBtn'
export default {
    name: 'DocumentTemplatePromptLists',
    // components:{
    //     CHideShowBtn
    // },
    data() {
        return {
            table_loading: true,
            dtp_lists_all: [],
            restoring: false,
            my_tab: 0,
            restoring_id: null,
        }
    },
    created() {
        this.getDocumentTemplatePrompts();
        this.$emit('activeTab', 0);
    },
    computed: {
        dtp_lists_active: function() {
            return this.dtp_lists_all.filter((item) => item.deleted_at == null);
        },

        dt_lists_inactive: function() {
            return this.dtp_lists_all.filter((item) => item.deleted_at != null);
        },

        dtp_lists_fields: function () {
            let custom_fields= [
                { key: 'name', label: 'Name'},
                { key: 'description' },
                { key: 'prompt' },
            ]
            
            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Added' })
            }

            if(this.$store.getters.can('view-document-template')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        changeTab: function (item) {
            this.my_tab = item
        },      
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/drs/document-template-prompt/restore/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.dtp_lists_all.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                }
            })
        },
        copyTemplate: function (id) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to copy this template.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-template/copy-template/'+id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${response.data.data.name} Template has sucessfully added. Do you want to open this template?`,
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Yes, open template",
                            }).then((result) => {
                                if(result.value){ 
                                    this.$router.push(`/${this.$store.getters.getOrganizationName}/drs/document-template/${response.data.data.id}`);
                                } else {
                                    this.getDocumentTemplatePrompts()
                                }
                            })
                            this.$Progress.finish();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        getDocumentTemplatePrompts: function () {
            this.$Progress.start()
            axios.get('/drs/document-template-prompt/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.dtp_lists_all = []
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                                id:  response.data.data[i].id, 
                                name:  response.data.data[i].name, 
                                description:  response.data.data[i].description, 
                                prompt:  response.data.data[i].prompt, 
                                deleted_at: response.data.data[i].deleted_at,
                                created_at: this.changeDateFormatV2(response.data.data[i].created_at),
                                updated_at: this.changeDateFormat(response.data.data[i].updated_at)
                        }
                        this.dtp_lists_all.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
    },
}
</script>